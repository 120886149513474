<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<p>{{content.desc}}</p>
				<p>Event start from {{ moment(content.booking.startDate).format("MMMM DD, YYYY") }} to {{ moment(content.booking.endDate).format("MMMM DD, YYYY") }}.</p>
                <p>Type of Event: {{ content.booking.event }}</p>
                <p>Expected Number of Attendees: {{ content.booking.attendees }}</p>
                <p>Event Title: {{ content.booking.event_title }}</p>
                <p>Contact Name: {{ content.booking.name }}</p>
                <p>Contact Email: {{ content.booking.email }}</p>
                <p>Contact Phone: {{ formatNumber(content.booking.phone) }}</p>
                <p>{{ depositStates.find(item => item.value == content.booking.depositState).label }}</p>
                <p>Additional Services: {{ content.booking.additionalService.join(", ") }}</p>
                <Button @click="$emit('onSubmit')" class="button-filled button-color submit-btn bg-black text-white border-black" size="size" variant="abc">Complete Booking</Button>
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        }
    },
    methods: {
        formatNumber(phone) {
            return `(${phone?.substring(0, 3)}) ${phone?.substring(3, 6)}-${phone?.substring(6, 10)}`
        }
    },
    data() {
        return {
            footerProps,
            moment: moment,
            depositStates: [
                {label: "I have toured the venue already and ready to put a deposit to hold my date(s)", value: "toured"},
                {label: "I would like to place a soft hold and schedule a tour before placing a deposit", value: "soft_hold"},
                {label: "I have not toured the venue, but I am ready to put a deposit to hold my date(s)", value: "not_toured"},
            ],
        }
    },
}
</script>

<style>

</style>