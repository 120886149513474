<template>
  <BookingPreviewTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
    @onSubmit="onSubmit"
  />
</template>

<script>
import BookingPreviewTemplate from "@/components/templates/BookingPreview/BookingPreview";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";
import moment from "moment";
import { Settings } from "../../settings";

export default {
  components: {
    BookingPreviewTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Rental Agreement",
        desc: "Please review the rental agreement below and sign to finalize your booking. Your deposit is non-refundable. A copy of the signed agreement will be emailed to you.",
        booking: {
          startDate: null,
          endDate: null,
          event: "",
          attendees: "",
          event_title: "",
          name: "",
          email: "",
          phone: "",
          additionalService: [],
          depositState: "toured",
        },
      },
      product: null,
      event: null,
    };
  },
  async created() {
    const events = await this.actions.event.getEvents({
      where: { id: this.$route.query.eId },
      getValues: true,
    });
    if (this.state.product.products?.length === 0) {
      await this.actions.product.getProducts();
    }
    console.log(events);
    const event = events[0];
    this.event = event;
    this.content.booking.startDate = moment(event?.days?.[0]?.startDate).format(
      "MMMM DD, YYYY"
    );
    this.content.booking.endDate = moment(event?.days?.[0]?.endDate).format(
      "MMMM DD, YYYY"
    );
    this.content.booking.event = event.metadata.type;
    this.content.booking.attendees = event.metadata.attendees;
    this.content.booking.event_title = event.name;
    this.content.booking.name =
      event.creator.firstName + " " + event.creator.lastName;
    this.content.booking.email = event.creator.email;
    this.content.booking.phone = event.creator.phones?.[0]?.number;
    const services = [];
    this.state.product.products
      ?.filter((p) => event.metadata.services?.find((s) => s === p?.id))
      ?.map((p) => services?.push(p?.name));
    this.content.booking.additionalService = services;
  },
  methods: {
    async onSubmit() {
      const saveCartParams = {
        findMarketplace: false,
        addItems: [
          {
            quantity: 1,
            productId: Settings.productId,
            originalProductId: Settings.productId,
            deliverBy: new Date(),
            deliverTo: { id: Settings.siteId },
          },
        ],
        eventId: this.event?.id,
      };
      const saveCart = await this.actions.cart.saveCart(saveCartParams);

      this.$router.push({
        name: "Signature",
        query: {
          cId: saveCart?.id,
          eId: this.$route.query?.eId,
          uId: this.$route.query?.uId,
          rId: this.$route.query?.rId,
        },
      });
    },
  },
};
</script>

<style scoped></style>
